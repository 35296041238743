<mat-dialog-content class="mat-typography">
    <h1 mat-dialog-title>{{ title }}</h1>
    {{ text }}
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button
        mat-icon-button
        mat-dialog-close
        cdkFocusInitial
        [attr.aria-label]="cancelButton"
        [matTooltip]="cancelButton"
    >
        <mat-icon>arrow_back</mat-icon>
    </button>

    <button
        mat-icon-button
        (click)="dialogRef.close(true)"
        [attr.aria-label]="successButton"
        [matTooltip]="successButton"
    >
        <mat-icon>check</mat-icon>
    </button>
</mat-dialog-actions>
