import { Environment } from '@app/shared';
import { ENV_DEV_PROJECT_ID } from '@lead-in/core';

export const environment: Environment = {
    production: false,
    firebaseConfig: {
        apiKey: 'AIzaSyAMMm2fJ415YxEVEYViG_snVEUnmRoCvE8',
        authDomain: `${ENV_DEV_PROJECT_ID}.firebaseapp.com`,
        projectId: ENV_DEV_PROJECT_ID,
        storageBucket: `${ENV_DEV_PROJECT_ID}.appspot.com`,
        messagingSenderId: '861780875457',
        appId: '1:861780875457:web:627ef1ec504b60a5815c40',
    },
    backendURL: 'https://develop.api.leadin.gfn.de',
};
