import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, input, OnDestroy, ViewChild } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import {
    CoachCentralOrder,
    COACHING_UNIT_FORMAT_LABELS,
    COACHING_UNIT_STATE_LABELS,
    COACHING_UNIT_TYPE_LABELS,
    UNKNOWN_TEXT,
} from '@lead-in/core';
import { map } from 'rxjs';

@Component({
    selector: 'app-order-list',
    templateUrl: './order-list.component.html',
    styleUrl: './order-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatSortModule,
        MatTableModule,
        RouterModule,
    ],
})
export class OrderListComponent implements AfterViewInit, OnDestroy {
    readonly typeLabels = COACHING_UNIT_TYPE_LABELS as unknown as Record<string, string>;
    readonly formatLabels = COACHING_UNIT_FORMAT_LABELS as unknown as Record<string, string>;
    readonly stateLabels = COACHING_UNIT_STATE_LABELS as unknown as Record<string, string>;

    readonly filterLabels = {
        ...this.typeLabels,
        ...this.formatLabels,
        ...this.stateLabels,
    };

    readonly UNKNOWN_TEXT = UNKNOWN_TEXT;

    readonly orders = input.required<CoachCentralOrder[] | null>();
    readonly subscription = toObservable(this.orders)
        .pipe(
            map((orders) => {
                this.sortedOrders.data = orders || [];
                return this.sortedOrders;
            })
        )
        .subscribe();

    readonly displayedColumns: (keyof CoachCentralOrder)[] = [
        'orderNumber',
        'customerNumber',
        'date',
        'type',
        'format',
        'unitCount',
        'state',
    ];

    sortedOrders: MatTableDataSource<CoachCentralOrder> = new MatTableDataSource<CoachCentralOrder>([]);

    @ViewChild(MatSort) sort: MatSort | null = null;

    ngAfterViewInit(): void {
        if (this.sort) {
            this.sortedOrders.sort = this.sort;
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    /**
     * Updates the MatTableDataSource with the given filter string.
     *
     * It first tries to find an exact match with the given filter string in the filterLabels.
     * If no match is found, it will filter by the filter string.
     *
     * @param event The event that triggered the filter update, required to get the input value.
     */
    filterTable(event: Event): void {
        let filterString = (event.target as HTMLInputElement).value.trim().toLocaleLowerCase();

        if (filterString?.length >= 3) {
            for (const [key, label] of Object.entries(this.filterLabels)) {
                if (label.toLocaleLowerCase().includes(filterString)) {
                    const find = this.filterLabels[key].toLocaleLowerCase() === filterString;
                    if (find) {
                        filterString = key;
                        break;
                    }
                }
            }
            this.sortedOrders.filter = filterString;
        } else {
            this.sortedOrders.filter = '';
        }
    }
}
