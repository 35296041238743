import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { CoachingReportDownloadErrorComponent } from '@app/components/dialogs/coaching-report-download-error/coaching-report-download-error.component';
import { ApiService, AuthService } from '@app/shared';
import {
    CoachCentralDetailLead,
    COACHING_UNIT_FORMAT_LABELS,
    CoachingReport,
    CoachingUnit,
    CoachingUnitFormat,
    COMPLETED_STATES,
    getNumDate,
    ProfileCoachCentral,
    UNKNOWN_TEXT,
} from '@lead-in/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, first, of } from 'rxjs';
import fs from 'file-saver';

export interface ProcessViewDialogData {
    lead: CoachCentralDetailLead;
}

interface CoachingUnitExtended extends Pick<CoachingUnit, 'date' | 'documentation' | 'format' | 'unitCount'> {
    unitCountTotal: string;
    showSignatureCoach: boolean;
    showSignatureLead: boolean;
}

@UntilDestroy()
@Component({
    selector: 'app-process-view',
    templateUrl: './process-view.component.html',
    styleUrl: './process-view.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatDialogModule, MatIconModule, MatProgressSpinnerModule, MatTableModule],
})
export class ProcessViewComponent implements OnInit {
    private readonly api = inject(ApiService);
    private readonly dialog = inject(MatDialog);
    private readonly dialogRef = inject(MatDialogRef<ProcessViewComponent>);

    readonly data = inject<ProcessViewDialogData>(MAT_DIALOG_DATA);
    readonly coachingUnitsExtended = signal<CoachingUnitExtended[]>([]);
    readonly profile = signal<ProfileCoachCentral | null>(null);
    readonly loadingCoachingUnits = signal(false);
    readonly loadingProfile = signal(false);
    readonly AuthService = inject(AuthService);

    readonly UNKNOWN_TEXT = UNKNOWN_TEXT;

    displayedColumns: string[] = [
        'date',
        'documentation',
        'format',
        'unitCount',
        'unitCountTotal',
        'signatureCoach',
        'signatureLead',
    ];

    ngOnInit(): void {
        this.getCoachingUnits();
        this.loadProfile();
    }

    getCoachingUnits(): void {
        this.loadingCoachingUnits.set(true);
        this.api
            .coachingUnitsRead({ leadId: this.data.lead.id })
            .then((response) => {
                const coachingUnits = response.coachingUnits
                    .filter((unit) => unit.type === 'coaching')
                    .toSorted((a, b) => a.numDate - b.numDate);
                const coachingUnitsExtended: CoachingUnitExtended[] = [];

                const todayNumDate = getNumDate();

                let currentUnitCount = 0;
                for (const coachingUnit of coachingUnits) {
                    if (coachingUnit.numDate <= todayNumDate) {
                        currentUnitCount += coachingUnit.unitCount;
                        const coachingUnitExtended: CoachingUnitExtended = {
                            ...coachingUnit,
                            date: new Date(coachingUnit.date).toISOString().split('T')[0],
                            unitCountTotal: `${currentUnitCount} von ${this.data.lead.maxNumOfCoachingUnits}`,
                            showSignatureCoach: coachingUnit.state !== 'new',
                            showSignatureLead: COMPLETED_STATES.includes(coachingUnit.state),
                        };
                        coachingUnitsExtended.push(coachingUnitExtended);
                    }
                }

                this.coachingUnitsExtended.set(coachingUnitsExtended);
            })
            .finally(() => {
                this.loadingCoachingUnits.set(false);
            });
    }

    loadProfile(): void {
        this.loadingProfile.set(true);
        this.api
            .profileRead()
            .then((data) => {
                this.profile.set(data);
            })
            .finally(() => {
                this.loadingProfile.set(false);
            });
    }

    async print(): Promise<void> {
        this.api
            .coachingReportGenerate(this.generateCoachingReport())
            .pipe(
                first(),
                catchError(() => {
                    this.dialog.open(CoachingReportDownloadErrorComponent);
                    return of(null);
                }),
                untilDestroyed(this)
            )
            .subscribe((blob) => {
                if (!blob) return;
                const fileName = this.generateFileName();
                const file = new File([blob], fileName, { type: 'application/pdf', lastModified: Date.now() });
                fs.saveAs(file, fileName);
            });
    }

    private generateCoachingReport(): CoachingReport {
        return {
            leadFullName: this.data.lead.fullName ?? 'Unbekannt',
            leadFullNameReversed: this.data.lead.fullNameReversed ?? 'Unbekannt',
            customerNumber: this.data.lead.customerNumber ?? '-',
            coachFullName: `${this.profile?.()?.firstName ?? 'Unbekannt'} ${this.profile?.()?.lastName ?? 'Unbekannt'}`,
            duration: `${new Date(this.data.lead.dateFrom ?? '').toLocaleDateString('de-DE')} - ${new Date(this.data.lead.dateTo ?? '').toLocaleDateString('de-DE')}`,
            maxNumOfCoachingUnits: this.data.lead.maxNumOfCoachingUnits,
            module: `Modul${
                this.data.lead.topOfferProductNames.length > 1
                    ? ' (' + this.data.lead.topOfferProductNames.length + ')'
                    : ''
            }: ${this.data.lead.topOfferProductNames.join(', ')}`,
            coachingDates: this.coachingUnitsExtended().map((unit) => ({
                date: new Date(unit.date).toLocaleDateString('de-DE'),
                documentation: unit.documentation,
                unitCount: unit.unitCount,
                unitCountTotal: unit.unitCountTotal,
                showSignatureCoach: unit.showSignatureCoach,
                showSignatureLead: unit.showSignatureLead,
                format: unit.format ? COACHING_UNIT_FORMAT_LABELS[unit.format] : '',
            })),
        };
    }

    private generateFileName(): string {
        return `Coachingverlauf_Stundennachweis_${this.data.lead.customerNumber}.pdf`;
    }

    showCoachingUnitFormatLabel(format: CoachingUnitFormat): string {
        return COACHING_UNIT_FORMAT_LABELS[format] || UNKNOWN_TEXT;
    }
}
