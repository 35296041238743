import { Component, inject, signal, WritableSignal, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Meta } from '@angular/platform-browser';
import { Router, RouterOutlet } from '@angular/router';
import { Profile, UNKNOWN_TEXT } from '@lead-in/core';
import { getAuth, onAuthStateChanged, Unsubscribe, User } from 'firebase/auth';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { AuthService, NotificationService } from '@app/shared';

// TODO: In Firebase => Hosting immer ergänzen!
// TODO: In Firebase => Autorisierte Domains ergänzen: z.B. gfn-coach-central-dev.firebaseapp.com
// TODO: Better ?? instead of ||
// TODO: Rückgabewerte und Datei-Formatierungen
// TODO: CSP Fehler, u.a. "Cross-Origin-Opener-Policy policy would block the window.close call."

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatToolbarModule, RouterOutlet],
})
export class AppComponent implements OnDestroy {
    private readonly router = inject(Router);
    private readonly authService = inject(AuthService);
    private readonly notificationService = inject(NotificationService);
    private readonly meta = inject(Meta);

    private readonly auth = getAuth();
    private readonly unsubscribeOnAuthStateChanged: Unsubscribe | null = onAuthStateChanged(this.auth, (user) => {
        this.currentUser.set(user);
        this.checkInactive(user);
    });
    private unsubscribeOnSnapshot: Unsubscribe | null = null;

    readonly currentUser: WritableSignal<User | null> = signal(null);
    readonly title = 'Coach Zentrale';
    readonly UNKNOWN_TEXT = UNKNOWN_TEXT;

    constructor() {
        this.meta.addTag({
            name: 'description',
            content: 'Dies ist ihre persönliche Coach Zentrale.',
        });
    }

    /**
     * Navigates to the profile page.
     */
    openProfile(): void {
        this.router.navigate(['/profile']);
    }

    /**
     * Logs the user out of the application.
     */
    logout(): void {
        this.authService.logout();
    }

    ngOnDestroy(): void {
        if (this.unsubscribeOnAuthStateChanged) {
            this.unsubscribeOnAuthStateChanged();
        }
        if (this.unsubscribeOnSnapshot) {
            this.unsubscribeOnSnapshot();
        }
    }

    /**
     * Checks if the user is inactive and logs them out if so.
     * @param user - The user to check.
     */
    private async checkInactive(user: User | null): Promise<void> {
        if (user) {
            const db = getFirestore();
            const userRef = doc(db, 'users', user.uid);
            this.unsubscribeOnSnapshot = onSnapshot(userRef, async (docSnap) => {
                const user = docSnap.data() as Profile;
                if (user != null && user.inactive === true) {
                    this.authService.logout();
                    this.notificationService.error('Ihr Konto wurde deaktiviert.');
                    if (this.unsubscribeOnSnapshot) {
                        this.unsubscribeOnSnapshot();
                    }
                }
            });
        }
    }
}
