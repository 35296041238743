import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, input, OnDestroy, ViewChild } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { ApiService, NotificationService } from '@app/shared';
import {
    CoachCentralFileDownloadResponse,
    CompleteAccountingCreditForCoach,
    convertNumberDateToTime,
} from '@lead-in/core';
import { map } from 'rxjs';
import fs from 'file-saver';

interface ExtendedCompleteAccountingCreditForCoach extends CompleteAccountingCreditForCoach {
    parsedFromDate: number;
    parsedToDate: number;
}

@Component({
    selector: 'app-accounting-credit-list',
    templateUrl: './accounting-credit-list.component.html',
    styleUrl: './accounting-credit-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule, MatTableModule, RouterModule],
})
export class AccountingCreditListComponent implements AfterViewInit, OnDestroy {
    private readonly api = inject(ApiService);
    private readonly notificationService = inject(NotificationService);

    readonly accountingCredits = input.required<CompleteAccountingCreditForCoach[] | null>();
    readonly subscription = toObservable(this.accountingCredits)
        .pipe(
            map((accountingCredits) => {
                this.sortedAccountingCredits.data = (accountingCredits || []).map((accountingCredit) => ({
                    ...accountingCredit,
                    parsedFromDate: convertNumberDateToTime(accountingCredit.fromDate),
                    parsedToDate: convertNumberDateToTime(accountingCredit.toDate),
                }));
                return this.sortedAccountingCredits;
            })
        )
        .subscribe();

    readonly displayedColumns = ['createdAt', 'dateFromAndTo', 'link'];

    sortedAccountingCredits: MatTableDataSource<ExtendedCompleteAccountingCreditForCoach> =
        new MatTableDataSource<ExtendedCompleteAccountingCreditForCoach>([]);

    @ViewChild(MatSort) sort: MatSort | null = null;

    ngAfterViewInit(): void {
        if (this.sort) {
            this.sortedAccountingCredits.sort = this.sort;
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    /**
     * Downloads a file from a given storage path and saves it locally with the specified file name.
     *
     * @param storagePath - The path to the file in storage to be downloaded.
     * @param fileName - The name to use when saving the downloaded file locally.
     * @throws {Error} Throws an error if the file download fails or if the file conversion fails.
     */
    downloadFile(storagePath: string, fileName: string): void {
        this.api
            .fileDownload({ storagePath })
            .then(async (response: CoachCentralFileDownloadResponse) => {
                const fileBase64String = response.fileBase64String;
                const fileType = response.fileType;
                if (!fileBase64String?.length || !fileType?.length) {
                    throw new Error('Fehler beim Herunterladen der Datei.');
                }
                const blob = this.base64ToBlob(fileBase64String, fileType);
                fs.saveAs(blob, fileName);
            })
            .catch((error) => {
                this.notificationService.error(error?.message);
            });
    }

    /**
     * Converts a Base64 encoded string to a Blob object.
     *
     * @param base64String - The Base64 encoded string to convert.
     * @param type - The MIME type of the resulting Blob.
     * @returns A Blob object representing the decoded data.
     * @throws {Error} Throws an error if the conversion fails.
     */
    private base64ToBlob(base64String: string, type: string): Blob {
        try {
            // Remove data URL scheme if present
            const base64Data = base64String.replace(/^data:.+;base64,/, '');
            const byteCharacters = atob(base64Data); // Decode Base64 string
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type });
            return blob;
        } catch (error) {
            throw new Error('Fehler beim Konvertieren der Datei.');
        }
    }
}
