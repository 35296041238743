@if (orders() === null) {
    <p>Klicken Sie auf "Neu laden" um Daten zu laden</p>
} @else {
    <div class="row">
        <div class="col-12">
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input
                    matInput
                    type="text"
                    (keyup)="filterTable($event)"
                    placeholder="Filter nach Auftrags-ID, Kd.-Nr., Typ, Format, Terminstatus (min. 3 Zeichen)"
                />
            </mat-form-field>
        </div>
    </div>

    <table
        mat-table
        [dataSource]="sortedOrders"
        class="mat-elevation-z1"
        matSort
    >
        <ng-container matColumnDef="orderNumber">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Auftrags-ID
            </th>
            <td
                mat-cell
                *matCellDef="let element"
            >
                {{ element.orderNumber || UNKNOWN_TEXT }}
            </td>
        </ng-container>

        <ng-container matColumnDef="customerNumber">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Kd.-Nr.
            </th>
            <td
                mat-cell
                *matCellDef="let element"
            >
                {{ element.customerNumber || UNKNOWN_TEXT }}
            </td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Termindatum
            </th>
            <td
                mat-cell
                *matCellDef="let element"
            >
                {{ element.date | date }}
            </td>
        </ng-container>

        <ng-container matColumnDef="unitCount">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                UEs
            </th>
            <td
                mat-cell
                *matCellDef="let element"
            >
                {{ element.unitCount }}
            </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Typ
            </th>
            <td
                mat-cell
                *matCellDef="let element"
            >
                {{ typeLabels[element.type] }}
            </td>
        </ng-container>

        <ng-container matColumnDef="format">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Format
            </th>
            <td
                mat-cell
                *matCellDef="let element"
            >
                {{ formatLabels[element.format] || '-' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="state">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Terminstatus
            </th>
            <td
                mat-cell
                *matCellDef="let element"
            >
                {{ stateLabels[element.state] }}
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
        ></tr>
    </table>
}
