import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isValidBIC, isValidIBAN } from 'ibantools';

/**
 * A validator function that returns null if the IBAN is valid, otherwise an object with an 'iban' property set to true.
 */
export function validateIBAN(control: AbstractControl): ValidationErrors | null {
    const iban = control.value;
    return isValidIBAN(iban) ? null : { iban: true };
}

/**
 * A validator function that returns null if the BIC is valid, otherwise an object with an 'bic' property set to true.
 */
export function validateBIC(control: AbstractControl): ValidationErrors | null {
    const bic = control.value;
    return isValidBIC(bic) ? null : { bic: true };
}
