import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle,
} from '@angular/material/dialog';

@Component({
    selector: 'app-coaching-report-download-error',
    standalone: true,
    imports: [MatDialogContent, MatDialogActions, MatDialogTitle, MatButton, MatDialogClose],
    templateUrl: './coaching-report-download-error.component.html',
    styleUrl: './coaching-report-download-error.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoachingReportDownloadErrorComponent {
    readonly dialogRef = inject(MatDialogRef<CoachingReportDownloadErrorComponent>);
}
