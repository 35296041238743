@if (accountingCredits() === null) {
    <p>Klicken Sie auf "Neu laden" um Daten zu laden</p>
} @else {
    <table
        mat-table
        [dataSource]="sortedAccountingCredits"
        class="mat-elevation-z1"
        matSort
        matSortActive="createdAt"
        matSortDirection="desc"
    >
        <ng-container matColumnDef="createdAt">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Erstellt am
            </th>
            <td
                mat-cell
                *matCellDef="let element"
            >
                {{ element.createdAt | date }}
            </td>
        </ng-container>

        <ng-container matColumnDef="dateFromAndTo">
            <th
                mat-header-cell
                *matHeaderCellDef
            >
                Von - Bis
            </th>
            <td
                mat-cell
                *matCellDef="let element"
            >
                @if (element.parsedFromDate === -1 || element.parsedToDate === -1) {
                    Fehler beim Parsen der Daten
                } @else {
                    {{ element.parsedFromDate | date }} - {{ element.parsedToDate | date }}
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="link">
            <th
                mat-header-cell
                *matHeaderCellDef
            >
                Datei-Link
            </th>
            <td
                mat-cell
                *matCellDef="let element"
            >
                @if (element.creditsFileUrl?.length || element.creditsFileName?.length) {
                    <button
                        mat-flat-button
                        (click)="downloadFile(element.creditsFileUrl, element.creditsFileName)"
                    >
                        <mat-icon>description</mat-icon>
                        {{ element.creditsFileName }}
                    </button>
                } @else {
                    Keine Datei vorhanden
                }
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
        ></tr>
    </table>
}
