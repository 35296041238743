<h2 mat-dialog-title>Download-Fehler</h2>
<mat-dialog-content> Es gab einen Fehler beim Herunterladen des Berichts. Bitte erneut versuchen. </mat-dialog-content>
<mat-dialog-actions>
    <button
        mat-button
        mat-dialog-close
        cdkFocusInitial
    >
        Ok
    </button>
</mat-dialog-actions>
