import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthService } from '@app/shared';
import { environment } from '@env/environment';
import { getFunctions, HttpsCallable, httpsCallable } from 'firebase/functions';
import {
    AccountingCreditsReadResponse,
    CoachCentralCoachingUnitCreateData,
    CoachCentralCoachingUnitDeleteData,
    CoachCentralCoachingUnitMailSendData,
    CoachCentralCoachingUnitsReadData,
    CoachCentralCoachingUnitsReadResponse,
    CoachCentralCoachingUnitUpdateData,
    CoachCentralFileDownloadData,
    CoachCentralFileDownloadResponse,
    CoachCentralLeadHelpMailSendData,
    CoachCentralLeadReadData,
    CoachCentralLeadReadResponse,
    CoachCentralLeadsReadResponse,
    CoachCentralLeadUpdateData,
    CoachCentralOrdersReadResponse,
    CoachCentralType,
    CoachingReport,
    isCoachCentralFileDownloadData,
    ProfileCoachCentral,
    region,
} from '@lead-in/core';
import { getApp } from 'firebase/app';
import { from, Observable, of, switchMap } from 'rxjs';
import { NotificationService } from '../notification';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    private readonly functions = getFunctions(getApp(), region);
    private readonly notifications = inject(NotificationService);
    private readonly http = inject(HttpClient);
    private readonly authService = inject(AuthService);

    private readonly functionName = 'coachCentral';

    readonly profileRead = this.createApiCall(
        'profileRead',
        httpsCallable<void, ProfileCoachCentral>(this.functions, this.functionName)
    );

    readonly profileUpdate = this.createApiCall(
        'profileUpdate',
        httpsCallable<ProfileCoachCentral, void>(this.functions, this.functionName)
    );

    readonly leadsReads = this.createApiCall(
        'leadsRead',
        httpsCallable<void, CoachCentralLeadsReadResponse>(this.functions, this.functionName)
    );

    readonly leadRead = this.createApiCall(
        'leadRead',
        httpsCallable<CoachCentralLeadReadData, CoachCentralLeadReadResponse>(this.functions, this.functionName)
    );

    readonly leadUpdate = this.createApiCall(
        'leadUpdate',
        httpsCallable<CoachCentralLeadUpdateData, void>(this.functions, this.functionName)
    );

    readonly leadHelpMailSend = this.createApiCall(
        'leadHelpMailSend',
        httpsCallable<CoachCentralLeadHelpMailSendData, void>(this.functions, this.functionName)
    );

    readonly coachingUnitsRead = this.createApiCall(
        'coachingUnitsRead',
        httpsCallable<CoachCentralCoachingUnitsReadData, CoachCentralCoachingUnitsReadResponse>(
            this.functions,
            this.functionName
        )
    );

    readonly coachingUnitCreate = this.createApiCall(
        'coachingUnitCreate',
        httpsCallable<CoachCentralCoachingUnitCreateData, void>(this.functions, this.functionName)
    );

    readonly coachingUnitUpdate = this.createApiCall(
        'coachingUnitUpdate',
        httpsCallable<CoachCentralCoachingUnitUpdateData, void>(this.functions, this.functionName)
    );

    readonly coachingUnitDelete = this.createApiCall(
        'coachingUnitDelete',
        httpsCallable<CoachCentralCoachingUnitDeleteData, void>(this.functions, this.functionName)
    );

    readonly coachingUnitMailSend = this.createApiCall(
        'coachingUnitMailSend',
        httpsCallable<CoachCentralCoachingUnitMailSendData, void>(this.functions, this.functionName)
    );

    readonly ordersRead = this.createApiCall(
        'ordersRead',
        httpsCallable<void, CoachCentralOrdersReadResponse>(this.functions, this.functionName)
    );

    readonly accountingCreditFilesRead = this.createApiCall(
        'accountingCreditFilesRead',
        httpsCallable<void, AccountingCreditsReadResponse>(this.functions, this.functionName)
    );

    readonly fileDownload = this.createApiCall(
        'fileDownload',
        httpsCallable<CoachCentralFileDownloadData, CoachCentralFileDownloadResponse>(this.functions, this.functionName)
    );

    // TODO: LEADIN-2292: error handling
    coachingReportGenerate(report: CoachingReport): Observable<Blob | null> {
        const token = from(this.authService.getToken());

        return token.pipe(
            switchMap((token) => {
                if (!token) {
                    return of(null);
                }
                const headers = new HttpHeaders({
                    Authorization: `Bearer ${token}`,
                });
                const reportUrl = environment.backendURL + '/coaching-report/generate-pdf';

                return this.http.post(reportUrl, report, {
                    headers,
                    responseType: 'blob',
                });
            })
        );
    }

    /**
     * Creates a callable API function that returns a promise with the response data.
     *
     * @param {CoachCentralType} requestType - The request type.
     * @param {HttpsCallable<RequestData, ResponseData>} callable - The callable API function.
     * @returns {(data: RequestData) => Promise<ResponseData>} A function that takes request data and returns a promise with the response data.
     */
    private createApiCall<RequestData = unknown, ResponseData = unknown>(
        requestType: CoachCentralType,
        callable: HttpsCallable<RequestData, ResponseData>
    ): (data: RequestData) => Promise<ResponseData> {
        return (data: RequestData) => {
            if (environment.emulatorUrl?.length) {
                this.functions.customDomain = environment.emulatorUrl;
                /* For the emulator, we need a leading slash */
                if (isCoachCentralFileDownloadData(data)) {
                    data.storagePath = `/${data.storagePath}`;
                }
            }

            return new Promise<ResponseData>((resolve, reject) => {
                callable({ requestType, ...data })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        this.notifications.error(error);
                        reject(error);
                    });
            });
        };
    }
}
